import React, { ComponentType, FC, useEffect } from 'react';

import { RedirectLoginOptions } from '@auth0/auth0-spa-js';
import { useAuth0 } from '@auth0/auth0-react';

/**
 * @ignore
 */
const defaultOnRedirecting = (): JSX.Element => <></>;

/**
 * @ignore
 */
const defaultReturnTo = (): string => `${window.location.pathname}${window.location.search}`;

/**
 * Options for the withAuthenticationRequired Higher Order Component
 */
interface WithAuthenticationRequiredOptions {
  /**
   * ```js
   * withAuthenticationRequired(Profile, {
   *   returnTo: '/profile'
   * })
   * ```
   *
   * or
   *
   * ```js
   * withAuthenticationRequired(Profile, {
   *   returnTo: () => window.location.hash.substr(1)
   * })
   * ```
   *
   * Add a path for the `onRedirectCallback` handler to return the user to after login.
   */
  returnTo?: string | (() => string);
  /**
   * ```js
   * withAuthenticationRequired(Profile, {
   *   onRedirecting: () => <div>Redirecting you to the login...</div>
   * })
   * ```
   *
   * Render a message to show that the user is being redirected to the login.
   */
  onRedirecting?: () => JSX.Element;
  /**
   * ```js
   * withAuthenticationRequired(Profile, {
   *   loginOptions: {
   *     appState: {
   *       customProp: 'foo'
   *     }
   *   }
   * })
   * ```
   *
   * Pass additional login options, like extra `appState` to the login page.
   * This will be merged with the `returnTo` option used by the `onRedirectCallback` handler.
   */
  loginOptions?: RedirectLoginOptions;
}

/**
 * ```js
 * const MyProtectedComponent = withAuthenticationRequired(MyComponent);
 * ```
 *
 * When you wrap your components in this Higher Order Component and an anonymous user visits your component
 * they will be redirected to the login page and returned to the page they we're redirected from after login.
 */
// eslint-disable-next-line @typescript-eslint/ban-types
const withAuthenticationRequired = <P extends object>(
  Component: ComponentType<P>,
  options: WithAuthenticationRequiredOptions = {},
): FC<P> => (props: P): JSX.Element => {
  const { isAuthenticated, isLoading, loginWithRedirect, logout, error } = useAuth0();
  const { returnTo = defaultReturnTo, onRedirecting = defaultOnRedirecting, loginOptions = {} } = options;

  useEffect(() => {
    if (isLoading || isAuthenticated) {
      return;
    }
    const opts = {
      ...loginOptions,
      appState: {
        ...loginOptions.appState,
        returnTo: typeof returnTo === 'function' ? returnTo() : returnTo,
      },
    };
    if (error) {
      logout({ returnTo: process.env.NEXT_PUBLIC_AUTH0_REDIRECT_URI });
      localStorage.removeItem('user_email');
    } else {
      (async (): Promise<void> => {
        await loginWithRedirect(opts);
      })();
    }
  }, [isLoading, isAuthenticated, loginWithRedirect, loginOptions, returnTo, error]);

  return isAuthenticated ? <Component {...props} /> : onRedirecting();
};

export default withAuthenticationRequired;
